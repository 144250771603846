import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuelidate from "vuelidate";
import VueI18n from "vue-i18n";
import esLocale from "@/i18n/es.json";
import enLocale from "@/i18n/en.json";
import frLocale from "@/i18n/fr.json";
import ptLocale from "@/i18n/pt.json";
import itLocale from "@/i18n/it.json";
import VueCheckI18nKey from "@/helpers/existsTranslation";
import store from "@/store";
import VueAuthentication from "@/helpers/tokens";
import VueApi from "@/api";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueFormat from "@/helpers/format";
import VueEmpty from "@/helpers/empty";
import VueEquals from "@/helpers/swallowEquality";
import CountryFlag from "vue-country-flag";
import VCalendar from "v-calendar";
import numberFormatter from "./utils/formatNumber";
import VueToast from "@/components/uiToast/index";
import VueSegmentAnalytics from "@/services/segment/index";

import "./index.css";

// Core Vue setup
Vue.config.productionTip = process.env.VUE_APP_ENVIRONMENT === "production";
Vue.config.performance = process.env.VUE_APP_ENVIRONMENT !== "production";
Vue.config.devtools = process.env.VUE_APP_ENVIRONMENT !== "production";

// Global event bus
window.eventBus = new Vue();

// api configuration
Vue.use(VueApi);

// Vuelidate plugin
Vue.use(Vuelidate);

// token helper methods
Vue.use(VueAuthentication);

// Use format polyfill
Vue.use(VueFormat);

// Use currency formatter
Vue.use(numberFormatter);

// Helper plugin to check emptyness
Vue.use(VueEmpty);

// Swallow equality
Vue.use(VueEquals);

//Use calendar plugin
Vue.use(VCalendar);

// Flags
Vue.component("country-flag", CountryFlag);

// I18n helper method to ensure the existance of keys
Vue.use(VueCheckI18nKey);

// Toast utility
Vue.use(VueToast);

// i18n config
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  silentFallbackWarn: true,
  messages: {
    es: esLocale,
    en: enLocale,
    fr: frLocale,
    pt: ptLocale,
    it: itLocale,
  },
});

// Sentry configuration
if (process.env.VUE_APP_ENVIRONMENT !== "development") {
  Sentry.init({
    Vue,
    dsn: "https://d57ebfce070a4a6ab666d8cb0f5094be@o992869.ingest.sentry.io/6155313",
    release: `box2box-customer-portal@${process.env.VUE_APP_VERSION}`,
    environment: process.env.VUE_APP_ENVIRONMENT,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.7,
    ignoreErrors: ["Network Error"],
    logErrors: true,
    trackComponents: true,
  });

  // Segment configuration
  Vue.use(VueSegmentAnalytics, {
    id: process.env.VUE_APP_SEGMENT_ID,
    router,
  });
}

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
